import { useIsCurrentUserHunter } from "Hooks/useIsCurrentUserHunter";
import HunterDashboardPage from "Pages/HunterDashboardPage";
import MerchantDashboardPage from "Pages/MerchantDashboardPage";

export const DashboardPage = () => {
  const isUserHunter = useIsCurrentUserHunter();

  return isUserHunter ? <HunterDashboardPage /> : <MerchantDashboardPage />;
};

export default DashboardPage;
