import { useQuery } from "@tanstack/react-query";
import { getPayPointsPublicIDTransactionsLatest } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const useLatestPayPointTransaction = (publicID: string | undefined) =>
  useQuery({
    queryKey: ["LATEST_PAY_POINT_TRANSACTION", publicID],
    queryFn: publicID
      ? processResponse(() => getPayPointsPublicIDTransactionsLatest(publicID))
      : undefined,
    enabled: !!publicID,
  });
