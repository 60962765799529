import { z } from "zod";
import { IncomeInformationModel } from "Components/Onboarding/BusinessEntity/types";
import {
  AverageTransactionValue,
  BusinessActivity,
  ExpectedMonthlyRevenue,
  PartyType,
  RelationType,
  SourceOfIncome,
} from "Infrastructure/Api/Api";

export const getSchema = (
  isHunter: boolean,
  hasIdentificationNumber: boolean,
  partyType?: PartyType,
) => {
  const isHunterWithoutIdentificationNumber =
    isHunter && !hasIdentificationNumber;
  const validationSchema: z.ZodType<IncomeInformationModel> = z
    .object({
      businessActivity: isHunterWithoutIdentificationNumber
        ? z.nativeEnum(BusinessActivity).optional()
        : z.nativeEnum(BusinessActivity),
      otherBusinessActivityDetails: z.string().optional(), // required when businessActivity is Other, optional otherwise
      incomeSource: isHunterWithoutIdentificationNumber
        ? z.nativeEnum(SourceOfIncome).optional()
        : z.nativeEnum(SourceOfIncome),
      incomeSourceDetail: z.string().optional(), // required when incomeSource is Other, optional otherwise
      expectedMonthlyRevenue: isHunter
        ? z.nativeEnum(ExpectedMonthlyRevenue).optional()
        : z.nativeEnum(ExpectedMonthlyRevenue),
      averageTransactionValue: isHunter
        ? z.nativeEnum(AverageTransactionValue).optional()
        : z.nativeEnum(AverageTransactionValue),
      taxResidence: z.number(),
      relationType:
        partyType === PartyType.LegalEntity
          ? z.nativeEnum(RelationType)
          : z.nativeEnum(RelationType).optional(),
      isPoliticallyExposed: z.boolean(),
    })
    .superRefine((data, ctx) => {
      if (
        data.businessActivity === BusinessActivity.Other &&
        !data.otherBusinessActivityDetails
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          expected: "string",
          received: "undefined",
          path: ["otherBusinessActivityDetails"],
        });
      }
      if (
        data.incomeSource === SourceOfIncome.Other &&
        !data.incomeSourceDetail
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          expected: "string",
          received: "undefined",
          path: ["incomeSourceDetail"],
        });
      }
    });

  return validationSchema;
};
