import { Box } from "@mui/material";
import { LicenseForm } from "Components/Users/UserDetailPageContent/Components/LicenseForm";
import { PartyLicenseDto } from "Infrastructure/Api/Api";

type LicensesProps = {
  licenses: Array<PartyLicenseDto>;
};

export const Licenses = ({ licenses }: LicensesProps) => (
  <Box display="flex" flexDirection="column" gap="1rem">
    {licenses.map(license => (
      <LicenseForm key={license.publicID} license={license} />
    ))}
  </Box>
);
