import { zodResolver } from "@hookform/resolvers/zod";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { useForm } from "react-hook-form";
import {
  IncomeInformationModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { useIsCurrentUserHunter } from "Hooks/useIsCurrentUserHunter";
import { getSchema } from "Components/Onboarding/BusinessEntity/Steps/IncomeInformationStep/schema";
import { HunterIncomeInformationFields } from "Components/Onboarding/BusinessEntity/Steps/IncomeInformationStep/HunterIncomeInformationFields";
import { MerchantIncomeInformationFields } from "Components/Onboarding/BusinessEntity/Steps/IncomeInformationStep/MerchantIncomeInformationFields";

export const IncomeInformationStep = () => {
  const { nextStep, getFormValues, isLoadingNextStep } = useOnboardingContext();
  const isUserHunter = useIsCurrentUserHunter();

  const incomeInformation = getFormValues(OnboardingSteps.IncomeInformation);
  const { partyType, identificationNumber } = getFormValues(
    OnboardingSteps.BusinessInformation,
  );

  const validationSchema = getSchema(
    isUserHunter,
    !!identificationNumber,
    partyType,
  );
  const defaultValues = {
    isPoliticallyExposed: false,
    ...incomeInformation,
    taxResidence: incomeInformation.taxResidence || 1, //To preselect Czechia (which has id 1 in DB)
  };

  const { control, handleSubmit, watch } = useForm<IncomeInformationModel>({
    resolver: zodResolver(validationSchema),
    defaultValues,
  });

  const isPoliticallyExposed = watch("isPoliticallyExposed");
  const businessActivity = watch("businessActivity");
  const incomeSource = watch("incomeSource");

  const onSubmit = (data: IncomeInformationModel) => {
    if (isPoliticallyExposed) {
      return;
    }
    nextStep(data);
  };

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        submitButtonLabel="Pokračovat"
        submitDisabled={isPoliticallyExposed}
        isSubmitting={isLoadingNextStep}
      >
        {isUserHunter ? (
          <HunterIncomeInformationFields
            hasIdentificationNumber={!!identificationNumber}
            control={control}
            partyType={partyType}
            incomeSource={incomeSource}
            businessActivity={businessActivity}
            isPoliticallyExposed={isPoliticallyExposed}
          />
        ) : (
          <MerchantIncomeInformationFields
            control={control}
            partyType={partyType}
            incomeSource={incomeSource}
            businessActivity={businessActivity}
            isPoliticallyExposed={isPoliticallyExposed}
          />
        )}
      </StepBox>
    </FullHeightForm>
  );
};
