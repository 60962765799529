import type { ApplicationLink } from "Components/Layout/Menu/Menu";
import { useMenuLinks } from "Hooks/Layout/useMenuLinks";
import { UserType } from "Hooks/useCurrentUserAccessRole";
import { useSelector } from "react-redux";
import type { RootStateType } from "State/Store";

type UseMenuReturn = {
  topMenu: Array<ApplicationLink>;
  bottomMenu: Array<ApplicationLink>;
};

const filterByAccessRightCode = (
  links: Array<ApplicationLink>,
  allowedAccessRightCodes: Array<string>,
) =>
  links.filter(
    ({ accessRightCode }) =>
      !accessRightCode ||
      allowedAccessRightCodes.some(code => code === accessRightCode),
  );

export const useMenu = (userType: UserType): UseMenuReturn => {
  const currentUserAccessRightCodes =
    useSelector((e: RootStateType) => e.auth).user?.accessRightCodes || [];

  const {
    contact,
    help,
    logout,
    overview,
    merchantPayment,
    transactions,
    bankAccounts,
    settings,
    users,
  } = useMenuLinks();

  const USER_TYPE_TO_MENU: Record<UserType, UseMenuReturn> = {
    admin: {
      topMenu: filterByAccessRightCode(
        [overview, settings, users],
        currentUserAccessRightCodes,
      ),
      bottomMenu: filterByAccessRightCode(
        [contact, help, logout],
        currentUserAccessRightCodes,
      ),
    },
    hunter: {
      topMenu: filterByAccessRightCode(
        [overview, transactions, bankAccounts, settings, users],
        currentUserAccessRightCodes,
      ),
      bottomMenu: filterByAccessRightCode(
        [contact, help, logout],
        currentUserAccessRightCodes,
      ),
    },
    merchant: {
      topMenu: filterByAccessRightCode(
        [
          overview,
          merchantPayment,
          transactions,
          bankAccounts,
          settings,
          users,
        ],
        currentUserAccessRightCodes,
      ),
      bottomMenu: filterByAccessRightCode(
        [contact, help, logout],
        currentUserAccessRightCodes,
      ),
    },
    customer: {
      topMenu: filterByAccessRightCode(
        [
          overview,
          merchantPayment,
          transactions,
          bankAccounts,
          settings,
          users,
        ],
        currentUserAccessRightCodes,
      ),
      bottomMenu: filterByAccessRightCode(
        [contact, help, logout],
        currentUserAccessRightCodes,
      ),
    },
  };

  return USER_TYPE_TO_MENU[userType];
};
