import { AddressType } from "Components/Onboarding/types";
import { z } from "zod";

export const addressValidationSchema: z.ZodType<AddressType> = z.object({
  addressID: z.number().optional(),
  street: z.string(),
  descriptionNumber: z.string(),
  orientationNumber: z.string().nullish(),
  city: z.string(),
  postalCode: z.string().nullish(),
});

export const businessAddressValidationSchema: z.ZodType<AddressType> = z.object({
  addressID: z.number().optional(),
  street: z.string().nullish(),
  descriptionNumber: z.string().nullish(),
  orientationNumber: z.string().nullish(),
  city: z.string().nullish(),
  postalCode: z.string().nullish(),
});
