import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { Box, Stack, Typography } from "@mui/material";
import { BankEnvironment } from "Infrastructure/Api/Api";
import { useBankingAuth } from "Hooks/useBankingAuth";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { BanksList } from "Components/Onboarding/BusinessEntity/Components/BanksList/BanksList";
import { BankButton } from "Components/Onboarding/BusinessEntity/Components/BanksList/BankButton/BankButton";

export const LicensePaymentStep = () => {
  const { nextStep } = useOnboardingContext();
  const { isPending, selectedEnvironment } = useBankingAuth();

  const onBankSelected = (environment: BankEnvironment | null = null) => {
    nextStep({ environment });
  };

  return (
    <FullHeightForm>
      <Box mb={5}>
        <Typography>
          K plné aktivaci licence je nutné provést její úhradu, která bude
          provedena převodem na účet. Vyberte banku, ze které chcete platbu
          provést.
        </Typography>
      </Box>
      <Stack gap={4}>
        <PaperTitle>Nabídka bank</PaperTitle>
        <BanksList
          onSelect={onBankSelected}
          pendingEnvironment={isPending ? selectedEnvironment : undefined}
        />
        <BankButton bank="Unknown" onClick={() => onBankSelected(null)} />
      </Stack>
    </FullHeightForm>
  );
};
