import { Typography } from "@mui/material";
import { LicenseCode } from "Infrastructure/Api/Api";
import { ReactNode } from "react";

type DescriptionProps = {
  licenseType: LicenseCode;
};

const LICENSE_DESCRIPTIONS: Record<LicenseCode, ReactNode> = {
  [LicenseCode.Gold]: "Licence Huntera v Legi.one s velkým fyzickým obrazem.",
  [LicenseCode.Silver]:
    "Licence Huntera v Legi.one se středním fyzickým obrazem.",
  [LicenseCode.Bronze]: "Licence Huntera v Legi.one s malým fyzickým obrazem.",
  [LicenseCode.Standard]: "Licence Huntera v Legi.one s digitálním obrazem.",
  [LicenseCode.Test]: (
    <>
      Vyzkoušejte si novou platební metodu <strong>Mo.one!</strong>
      <br />
      Koupí testovací licence přispějete spolku Abethandicap z.s. na pomoc
      osobám zdravotně postiženým. Testovací licence Vás neopravňuje k činnosti
      Huntera.
    </>
  ),
};

export const Description = ({ licenseType }: DescriptionProps) => {
  const description = LICENSE_DESCRIPTIONS[licenseType];

  return <Typography mb={1}>{description}</Typography>;
};
