import {
  FormModel,
  IncomeInformationModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { UserDetailModel } from "Components/Onboarding/types";
import { isValid } from "date-fns";
import {
  AddressDto,
  AddressType,
  PartyDto,
  PartyType,
  RelationType,
} from "Infrastructure/Api/Api";
import { DeepPartial } from "types";

export const getCompanyName = (party: PartyDto): string | undefined => {
  if (
    party.type === PartyType.SelfEmployed &&
    !party.companyName &&
    (party.firstName || party.lastName)
  ) {
    return [party.firstName, party.lastName].join(" ");
  }

  return party.companyName ?? undefined;
};

// this is used to update the main party, not related persons
export const getBusinessPartyDataFromForm = (
  form: DeepPartial<FormModel>,
  party: PartyDto,
): PartyDto => {
  const { address, companyName, contact, identificationNumber, partyType } =
    form[OnboardingSteps.BusinessInformation] ?? {};

  const otherAddresses = party.addresses.filter(
    ({ type }) => type !== AddressType.Headquarters,
  );
  const headquartersAddress = party.addresses.find(
    ({ type }) => type === AddressType.Headquarters,
  );

  return {
    ...party,
    type: partyType ?? party.type,
    companyName: companyName,
    companyNumber: identificationNumber,
    phone: contact?.phone,
    addresses: [
      {
        addressID: headquartersAddress?.addressID,
        street: address?.street,
        streetNumber: address?.descriptionNumber,
        orientationNumber: address?.orientationNumber,
        postalCode: address?.postalCode,
        municipality: address?.city,
        type: AddressType.Headquarters,
      },
      ...otherAddresses,
    ],
  };
};

export const getPartyDataFromForm = (
  form: FormModel,
  party: PartyDto,
): PartyDto => {
  const businessInformation = form[OnboardingSteps.BusinessInformation];
  const userDetails = form[OnboardingSteps.UserDetail];

  const pernamentPartyAddress = party.addresses.find(
    ({ type }) => type === AddressType.Permanent,
  );

  const addresses: AddressDto[] = [
    {
      addressID:
        userDetails.address?.addressID ?? pernamentPartyAddress?.addressID,
      type: AddressType.Permanent,
      street: userDetails.address.street,
      streetNumber: userDetails.address.descriptionNumber,
      orientationNumber: userDetails.address.orientationNumber,
      postalCode: userDetails.address.postalCode,
      municipality: userDetails.address.city,
    },
  ];

  const identificationValidFrom = userDetails.document.valid.from.toISOString();
  const identificationValidTo = userDetails.document.valid.to.toISOString();

  const partyDto: PartyDto = {
    ...party,
    type:
      // TODO: What party type use for legan entity?
      party.type === PartyType.LegalEntity
        ? PartyType.NaturalPerson
        : party.type,
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    personalNumber: userDetails.personalIdentificationNumber,
    birthPlace: userDetails.birthPlace,
    nationalityCountryID: userDetails.country,
    gender: userDetails.gender,
    isForeigner: false,
    phone: businessInformation.contact?.phone ?? party.phone,
    identification: userDetails.document.type,
    identificationNumber: userDetails.document.number,
    identificationValidFrom,
    identificationValidTo,
    identificationIssuer: userDetails.document.issued,
    addresses,
  };

  return partyDto;
};

export const getUserDetailFromParty = (
  party: PartyDto,
): DeepPartial<UserDetailModel> => {
  const pernamentAddress = party.addresses.find(
    ({ type }) => type === AddressType.Permanent,
  );

  return {
    address: {
      addressID: pernamentAddress?.addressID ?? undefined,
      city: pernamentAddress?.municipality,
      descriptionNumber: pernamentAddress?.streetNumber ?? undefined,
      orientationNumber: pernamentAddress?.orientationNumber ?? undefined,
      postalCode: pernamentAddress?.postalCode ?? undefined,
      street: pernamentAddress?.street ?? undefined,
    },
    firstName: party.firstName ?? undefined,
    lastName: party.lastName ?? undefined,
    gender: party.gender ?? undefined,
    birthPlace: party.birthPlace ?? undefined,
    country: party.nationalityCountryID ?? undefined,
    personalIdentificationNumber: party.personalNumber ?? undefined,
    document: {
      type: party.identification ?? undefined,
      issued: party.identificationIssuer ?? undefined,
      number: party.identificationNumber ?? undefined,
      valid: {
        from:
          party.identificationValidFrom &&
          isValid(new Date(party.identificationValidFrom))
            ? new Date(party.identificationValidFrom)
            : undefined,
        to:
          party.identificationValidTo &&
          isValid(new Date(party.identificationValidTo))
            ? new Date(party.identificationValidTo)
            : undefined,
      },
    },
  };
};

type InitialPartyFormData = {
  formData: DeepPartial<FormModel>;
  actingPerson: {
    partyPublicID?: string;
    relatedPartyPublicID?: string;
    isOwner?: boolean;
  };
};

export const getInitialFormDataFromParty = (
  party: PartyDto,
  form: DeepPartial<FormModel>,
): InitialPartyFormData => {
  const headquartersAddress = party.addresses.find(
    ({ type }) => type === AddressType.Headquarters,
  );

  const relatedParty = party.relatedParties?.find(
    ({ relationType }) => relationType !== RelationType.BeneficialOwner,
  );

  const incomeInformation: DeepPartial<IncomeInformationModel> =
    party.type === PartyType.LegalEntity && relatedParty
      ? {
          relationType: relatedParty.relationType,
        }
      : {};

  return {
    formData: {
      ...form,
      [OnboardingSteps.BusinessInformation]: {
        ...form[OnboardingSteps.BusinessInformation],
        // business type cannot be personal
        partyType:
          party.type !== PartyType.NaturalPerson ? party.type : undefined,
        address: {
          addressID: headquartersAddress?.addressID ?? undefined,
          city: headquartersAddress?.municipality,
          descriptionNumber: headquartersAddress?.streetNumber ?? undefined,
          orientationNumber:
            headquartersAddress?.orientationNumber ?? undefined,
          postalCode: headquartersAddress?.postalCode ?? undefined,
          street: headquartersAddress?.street ?? undefined,
        },
        companyName: getCompanyName(party),
        identificationNumber: party.companyNumber ?? undefined,
        contact: {
          phone: party.phone ?? "+420",
        },
      },
      [OnboardingSteps.IncomeInformation]: {
        ...form[OnboardingSteps.IncomeInformation],
        ...incomeInformation,
      },
      [OnboardingSteps.AdditionalInformation]: {
        ...form[OnboardingSteps.AdditionalInformation],
        owners: party.relatedParties
          ?.filter(
            ({ relationType, relatedPartyPublicID }) =>
              relationType === RelationType.BeneficialOwner &&
              relatedParty?.relatedPartyPublicID !== relatedPartyPublicID,
          )
          .map(({ relatedPartyPublicID }) => ({
            publicID: relatedPartyPublicID,
          })),
      },
      [OnboardingSteps.UserDetail]: {
        ...form[OnboardingSteps.UserDetail],
        ...getUserDetailFromParty(party),
      },
    },
    actingPerson: {
      relatedPartyPublicID: relatedParty?.publicID ?? undefined,
      partyPublicID: relatedParty?.relatedPartyPublicID ?? undefined,
      isOwner: party.relatedParties.some(
        ({ relationType, relatedPartyPublicID }) =>
          relationType === RelationType.BeneficialOwner &&
          relatedPartyPublicID === relatedParty?.relatedPartyPublicID,
      ),
    },
  };
};
