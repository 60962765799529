import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PARTY_LICENSE_QUERY_KEY } from "Api/Queries/Licenses/usePartyLicensesQuery";
import {
  UpdateLicenseCommandResult,
  UpdateLicenseRequest,
  putLicensesPublicID,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: (data: UpdateLicenseCommandResult) => void;
  onError?: () => void;
};

export const usePutLicensesMutation = ({ onSuccess, onError }: Props = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: processResponse(
      ({
        publicID,
        requestPayload,
      }: {
        publicID: string;
        requestPayload: UpdateLicenseRequest;
      }) => putLicensesPublicID(requestPayload, publicID),
    ),

    onSuccess: (data: UpdateLicenseCommandResult) => {
      queryClient.invalidateQueries({
        queryKey: [PARTY_LICENSE_QUERY_KEY],
      });
      onSuccess?.(data);
    },
    onError,
  });
};