import { useQuery } from "@tanstack/react-query";
import { getTransactionsPublicID } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const useTransactionsDetail = (publicID: string) =>
  useQuery({
    queryKey: ["TRANSACTIONS_DETAIL", publicID],
    queryFn: processResponse(() => getTransactionsPublicID(publicID)),
    enabled: !!publicID
  });
