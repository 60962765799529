import {
  FormModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { getUserDetailFromParty } from "Components/Onboarding/BusinessEntity/Utils/partyUtils";
import { PartyDto } from "Infrastructure/Api/Api";
import { DeepPartial } from "types";

export const getFormDataFromOCR = (
  party: PartyDto,
  form: DeepPartial<FormModel>,
): DeepPartial<FormModel> => {
  const userDataFromParty = getUserDetailFromParty(party);
  const userDataFromForm = form[OnboardingSteps.UserDetail];
  const formData = {
    ...form,
    [OnboardingSteps.UserDetail]: {
      ...userDataFromForm,
      ...userDataFromParty,
      country: userDataFromForm?.country || userDataFromParty?.country || 1,
    },
  };

  return formData;
};
