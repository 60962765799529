import { Box, CircularProgress } from "@mui/material";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { UserStatus } from "Infrastructure/Api/Api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, RouteProps, useLocation } from "react-router";
import { resetUser } from "State/Auth/AuthReducer";
import { RootStateType } from "State/Store";
import { getAppUrl } from "Utils/UrlUtils";

type AuthorizedRouteProps = RouteProps & {
  isUserAuthenticated: boolean;
  allowAnonymous?: boolean;
};

export const AuthenticatedRoute: React.FunctionComponent<
  React.PropsWithChildren<AuthorizedRouteProps>
> = props => {
  const { children, isUserAuthenticated, allowAnonymous } = props;
  const appUrl = getAppUrl();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state: RootStateType) => state.auth);
  const user = useLoggedInUser();

  useEffect(() => {
    if (!isUserAuthenticated && location.pathname !== "/") {
      dispatch(
        resetUser({ authRedirectUrl: location.pathname + location.search }),
      );
    }
  }, [dispatch, isUserAuthenticated, location.pathname, location.search]);

  if (auth.isUserFetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress color="inherit" sx={{ color: "grey.300" }} />
      </Box>
    );
  }

  if (!isUserAuthenticated) {
    return (
      <Navigate
        to={appUrl("sign-in")}
        state={{ from: location }}
        replace={true}
      />
    );
  }

  if (!allowAnonymous && user?.userStatus === UserStatus.Anonymous) {
    return (
      <Navigate
        to={appUrl("dashboard")}
        state={{ from: location }}
        replace={true}
      />
    );
  }

  if (user?.userStatus === UserStatus.Onboarding) {
    return <Navigate to={appUrl("onboarding")} replace={true} />;
  }

  return <>{children}</>;
};
