import { Box, Skeleton, Typography } from "@mui/material";
import { CarouselPicker } from "Components/Shared/Inputs/CarouselPicker/CarouselPicker";
import { AvailabilityInfo } from "./Components/AvailabilityInfo";
import { Description } from "./Components/Description";
import { Price } from "./Components/Price";
import { LicenseCode, LicenseTypeListItem } from "Infrastructure/Api/Api";
import { useLicenseQuery } from "Api/Queries/Licenses/useLicenseQuery";
import { LicenseCountryCode } from "Components/Onboarding/BusinessEntity/types";
import { CurrencyCode } from "Models/CurrencyCodes";
import { LICENSE_CODE_TO_DATA_MAP } from "Components/Onboarding/BusinessEntity/Utils/licenseUtils";

type LicensePickerProps = {
  onSelect: (license: LicenseCode) => void;
  defaultValue: LicenseCode;
  currentValue: LicenseCode;
  countryAlpha2Code: LicenseCountryCode;
  isDisabled?: boolean;
};

export const LicensePicker = ({
  onSelect,
  defaultValue,
  currentValue,
  countryAlpha2Code,
  isDisabled,
}: LicensePickerProps) => {
  const {
    data: { items: licenses } = {},
    isLoading,
    isError,
  } = useLicenseQuery(countryAlpha2Code);

  if (isLoading) {
    return <LicenseSkeleton />;
  }

  if (!licenses || isError) {
    return (
      <Typography color="error.main">
        Omlouváme se, licence se nepodařilo načíst. Zkuste to prosím později
      </Typography>
    );
  }

  const licensesMap = licenses.reduce((acc, license) => {
    acc[license.code] = license;
    return acc;
  }, {} as Record<LicenseCode, LicenseTypeListItem>);

  const filteredLicenseOptions = licenses
    .filter(
      ({ availableAmount, code }) =>
        availableAmount > 0 || code === defaultValue, // if the license tier isn't available anymore, but the user ordered it already (when it still was), include it for them
    )
    .sort((a, b) => a.price - b.price)
    .map(({ code }) => {
      const { title, czImg, skImg } = LICENSE_CODE_TO_DATA_MAP[code];
      return {
        title,
        value: code,
        imgSrc: countryAlpha2Code === LicenseCountryCode.CZ ? czImg : skImg,
      };
    });

  const maybeTestingLicense = filteredLicenseOptions.find(
    ({ value }) => value === LicenseCode.Test,
  );

  const rearrangedLicenseOptions = [
    ...filteredLicenseOptions.filter(({ value }) => value !== LicenseCode.Test),
    ...(maybeTestingLicense ? [maybeTestingLicense] : []),
  ];

  return (
    <>
      <CarouselPicker
        isDisabled={isDisabled}
        options={rearrangedLicenseOptions}
        onSelect={({ value }) => onSelect(value)}
        defaultValue={defaultValue}
      />
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        gap="1rem"
      >
        <Description licenseType={currentValue} />
        <AvailabilityInfo
          current={licensesMap[currentValue].availableAmount}
          max={licensesMap[currentValue].totalAmount}
        />
        <Price
          amount={licensesMap[currentValue].price}
          currencyCode={licensesMap[currentValue].currencyCode as CurrencyCode} //TODO Nice to have: validate with zod
        />
      </Box>
    </>
  );
};

export const LicenseSkeleton = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    gap={3}
    pt="0.75rem"
  >
    <Skeleton
      variant="rounded"
      width={210}
      height={366}
      sx={{ borderRadius: 4 }}
    />
    <Box display="flex" gap={1} justifyContent="center">
      <Skeleton variant="circular" width={12} height={12} />
      <Skeleton variant="circular" width={12} height={12} />
      <Skeleton variant="circular" width={12} height={12} />
      <Skeleton variant="circular" width={12} height={12} />
    </Box>
  </Box>
);
