import { useLoggedInUser } from "Hooks/useLoggedInUser";

export type UserType = "merchant" | "hunter" | "customer" | "admin";

export const useCurrentUserAccessRoles = () => {
  const user = useLoggedInUser();

  return user?.accessRoleCodes || [];
};

export const useCurrentUserRole = (): UserType | null => {
  const userRoles = useCurrentUserAccessRoles();

  if (userRoles.includes("Admin")) {
    return "admin";
  }

  if (userRoles.includes("Hunter")) {
    return "hunter";
  }

  if (userRoles.includes("Merchant")) {
    return "merchant";
  }

  if (userRoles.includes("Customer")) {
    return "customer";
  }

  return null;
};
