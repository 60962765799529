import { ChevronRight } from "@mui/icons-material";
import { ListItem, Typography, Box, IconButton, Snackbar } from "@mui/material";
import { LeadStatus } from "Api/Api";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { Colors } from "Components/Layout/Themes/Colors";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { CopyIcon } from "Components/Shared/Icons";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeadListAsync } from "State/Afl/LeadListState/LeadListState";
import { RootStateType } from "State/Store";
import { getUserVerificationDetailAsync } from "State/Verification/GetUserVerificationDetail/GetUserVerificationDetailState";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";
import { isNotFullyVerified } from "Utils/VerificationUtils";
import { useGetAffiliateLink } from "Api/Queries/Lead/useGetAffiliateLink";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledListItem = styled(ListItem)`
  padding-right: 0;
  padding-left: 0;
`;

const translateStatus = (status: LeadStatus) => {
  switch (status) {
    case LeadStatus.Invited:
      return "Pozván";
    case LeadStatus.Registered:
      return "Registrován";
    case LeadStatus.Completed:
    case LeadStatus.CompletedRewardSent:
      return "Zasíláme odměnu";
    case LeadStatus.CompletedRewardApplied:
      return "Odměna vyplacena";
  }
};

export const AffiliatePage = () => {
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const appUrl = getAppUrl();
  const dispatch = useDispatch();
  const { data: affiliateLink } = useGetAffiliateLink();

  useEffect(() => {
    dispatch(getLeadListAsync.request());
    return () => {
      dispatch(getLeadListAsync.success(null));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserVerificationDetailAsync.request());
  }, [dispatch]);

  const { leadList } = useSelector((e: RootStateType) => e.affiliate);
  const { detail, isLoading: isVerificationLoading } = useSelector(
    (e: RootStateType) => e.verification,
  );

  const isVerificationDisclaimerVisible =
    isNotFullyVerified(detail) && !isVerificationLoading;

  const canShowInvite = !isVerificationDisclaimerVisible;

  const handleCopyClick = async () => {
    if (affiliateLink?.url) {
      await navigator.clipboard.writeText(affiliateLink.url);
      setIsCopySuccess(true);
    }
  };

  return (
    <AuthenticatedLayout title="Pozvat přítele" goBackUrl={appUrl("settings")}>
      <StyledPageWrapper>
        <div className="left-content">
          <PrimaryPaper>
            <Typography variant={"h6"}>
              Staň se legendou & vydělávej dlouhodobě! 🚀
            </Typography>
            <Box mb={6} display={"flex"} flexDirection={"column"}>
              <p>
                Pozvi své známé do Legi.one a získej 10% provizi za každého
                nového huntera! 🏆 Ale tím to nekončí – pokud budou úspěšní,
                získáš dlouhodobé provize z jejich jednoho obchodu a o druhý se
                s tebou podělí o 50 %. 💰 Tobě se zároveň navýší provize z části
                Tvých obchodů.
              </p>
              <p>
                📩 Sdílej svůj affiliate kód teď a aktivně si buduj pasivní
                příjem!
              </p>
              <p>
                📜 Podmínky affiliate programu najdeš{" "}
                <a
                  href="https://legi.one/assets/cesta/Affiliate_Hunters_Rulers_11-2024.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  zde
                </a>
              </p>
            </Box>

            {affiliateLink?.url && (
              <Box mb={4}>
                <Typography fontWeight={600}>Váš affiliate odkaz:</Typography>
                <Box display="flex" alignItems="center">
                  <code>{affiliateLink.url}</code>
                  <IconButton
                    onClick={handleCopyClick}
                    aria-label="Kopírovat odkaz"
                    sx={{ p: 0, ml: 1 }}
                  >
                    <CopyIcon />
                  </IconButton>
                </Box>
                <Snackbar
                  open={isCopySuccess}
                  autoHideDuration={2000}
                  onClose={() => setIsCopySuccess(false)}
                  message="Odkaz byl zkopírován"
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  sx={{ bottom: "80px" }}
                />
              </Box>
            )}

            {isVerificationDisclaimerVisible && (
              <p>Před pozváním přátel je nutné provést ověření vašeho účtu</p>
            )}

            {canShowInvite && (
              <UnstyledLink to={appUrl("invite-lead")}>
                <PrimaryButton
                  type="button"
                  variant="contained"
                  color="info"
                  fullWidth
                >
                  Pozvat přítele
                </PrimaryButton>
              </UnstyledLink>
            )}
            {isVerificationDisclaimerVisible && (
              <UnstyledLink to={appUrl("verification")}>
                <PrimaryButton
                  type="button"
                  variant="contained"
                  color="info"
                  fullWidth
                >
                  Ověřit
                </PrimaryButton>
              </UnstyledLink>
            )}
          </PrimaryPaper>

          {!!leadList && !isVerificationDisclaimerVisible && (
            <>
              <br />
              <Typography
                fontWeight={600}
                color={Colors.DarkGrey}
                paddingBottom={"5px"}
              >
                Vaše pozvánky
              </Typography>
              {leadList.items.map((item, index) => (
                <StyledListItem key={item.code + index} divider>
                  <Row>
                    <Typography>{item.email}</Typography>
                    <Typography fontWeight={600}>
                      {translateStatus(item.status)}
                    </Typography>
                  </Row>
                </StyledListItem>
              ))}

              {isVerificationDisclaimerVisible && (
                <UnstyledLink
                  to={appUrl("verification")}
                  style={{ width: "100%" }}
                >
                  <StyledListItem divider>
                    <Row>
                      <Typography fontWeight={600}>{`Ověřit účet`}</Typography>
                      <ChevronRight />
                    </Row>
                  </StyledListItem>
                </UnstyledLink>
              )}
            </>
          )}
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default AffiliatePage;
