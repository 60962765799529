import * as React from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { FormInfo } from "Components/Shared/Inputs/FormInfo";
import { FieldErrorProps, useFieldError } from "Hooks/useFieldError";

type Props = CheckboxProps &
  FieldErrorProps & {
    label?: string | JSX.Element;
    formInfo?: { [key: string]: string };
    isLabelFirst?: boolean;
    isStrong?: boolean;
  };

const StyledWrapper = styled.div<{
  $withLabelPadding?: boolean;
  $isDisabled?: boolean;
}>`
  display: flex;
  user-select: none;
  align-items: center;

  & > label {
    display: flex;
    cursor: ${({ $isDisabled }) => ($isDisabled ? "default" : "pointer")};
    padding-left: ${props =>
      props.$withLabelPadding ? props.theme.spacing(2) : 0};
    width: 100%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;

  &.Mui-checked + label {
    .MuiTypography-root {
      font-weight: 400;
    }
  }
`;

const StyledError = styled.div`
  padding-left: ${props => props.theme.spacing(6)};
`;

const ErrorTypography = styled(Typography)`
  color: ${props => props.theme.colors.redDark};
`;

export const CheckboxInput: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { hasError, errorMessage, originalProps } = useFieldError(props);
  const { label, formInfo, isLabelFirst, isStrong, ...rest } = originalProps;

  const LabelElement = (
    <label htmlFor={props.name}>
      {typeof label === "string" ? (
        <Typography variant={isStrong ? "button" : undefined}>
          {label}
        </Typography>
      ) : (
        label
      )}
      <FormInfo formInfo={formInfo} name={props.name ?? ""}></FormInfo>
    </label>
  );
  return (
    <div>
      <StyledWrapper
        className="checkbox"
        $withLabelPadding={!isStrong}
        $isDisabled={props.disabled}
      >
        {isLabelFirst && label && LabelElement}
        <StyledCheckbox id={props.name} {...rest} />
        {!isLabelFirst && label && LabelElement}
      </StyledWrapper>
      {hasError && (
        // TODO Ryboš
        <StyledError>
          <ErrorTypography>{errorMessage}</ErrorTypography>
        </StyledError>
      )}
    </div>
  );
};
