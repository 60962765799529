import {
  AdditionalInfoStatus,
  BankVerificationStatus,
  IdentityVerificationStatus,
  PartyDto,
  RiskProfileStatus,
  UserDto,
} from "Infrastructure/Api/Api";

import { TableIDType } from "Components/Shared/PrimaryDataTable/PrimaryDataTable";
import styled from "styled-components";
import { formatDate } from "Utils/DateUtils";
import { VerificationCell } from "Components/Users/VerificationCell";
import { ReactNode } from "react";

export const isUserVerified = (user: UserDto) =>
  user.verificationRiskProfile === RiskProfileStatus.NoRisk &&
  user.verificationAdditionalInfoStatus === AdditionalInfoStatus.Verified &&
  user.verificationBankVerificationStatus === BankVerificationStatus.Verified &&
  user.verificationIdentityVerificationStatus ===
    IdentityVerificationStatus.Verified;

type CellID = TableIDType<UserDto> | TableIDType<PartyDto>;

//TODO: shouldn't use clicks on divs, bad for a11y, inaccessible without a mouse
const CellWrapper = styled.div`
  cursor: pointer;
`;

export const getCell = (id: CellID, data: UserDto) => {
  const idToCellData: Partial<Record<CellID, ReactNode>> = {
    verificationAdditionalInfoStatus: <VerificationCell user={data} />,
    login: data.login,
    firstName: data.party.firstName,
    lastName: data.party.lastName,
    companyName: data.party.companyName,
    dateCreated: formatDate(data.dateCreated, true),
    userID: `25${data.userID}`, // This better be only temporary (it's a variable symbol for license payment)
  };

  const cell = idToCellData[id];

  return <CellWrapper>{cell}</CellWrapper>;
};
