import { Grid, Typography } from "@mui/material";

type SettingsRowButtonProps = {
  onClick: () => void;
  name: string;
  icon: JSX.Element;
};

export const SettingsRowButton = ({
  onClick,
  name,
  icon,
}: SettingsRowButtonProps) => (
  <Grid
    container
    component="button"
    onClick={onClick}
    sx={({ palette, spacing }) => ({
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      border: "none",
      background: "none",
      cursor: "pointer",
      m: 0,
      p: spacing(4, 0),
      color: palette.text.secondary,
      borderBottom: `1px solid ${palette.divider}`,
    })}
  >
    <Grid item xs={1} display="flex" width="16px" height="16px">
      {icon}
    </Grid>
    <Grid item xs={10}>
      <Typography fontSize={16} textAlign={"left"}>
        {name}
      </Typography>
    </Grid>
  </Grid>
);
