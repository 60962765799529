import { Box, LinearProgress, Typography } from "@mui/material";
import { SearchAccountIcon } from "Components/Shared/Icons";
import { Resources, useResource } from "Translations/Resources";

const StepResources = Resources.Onboarding.Steps.Documents;

export const ProcessingOcr = () => {
  const { t } = useResource();

  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
      <Box marginTop={12}>
        <SearchAccountIcon />
      </Box>
      <Typography variant="body2">{t(StepResources.Processing)}</Typography>
      <Box sx={{ width: "20%" }}>
        <LinearProgress color={"info"} />
      </Box>
    </Box>
  );
};
