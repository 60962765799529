import {
  PartyDto,
  PartyType,
  UserVerificationUpdateDto,
  type UserAdminDetailDto,
} from "Infrastructure/Api/Api";
import { DownloadIdentificationsButton } from "Components/Users/UserDetailPageContent/Components/DownloadIdentificationsButton";
import { VerificationForm } from "Components/Users/UserDetailPageContent/Components/VerificationForm";
import { PartyForm } from "Components/Users/UserDetailPageContent/Components/PartyForm/PartyForm";
import { UserForm } from "Components/Users/UserDetailPageContent/Components/UserForm";
import { Box, Tab, Tabs } from "@mui/material";
import { ReactNode, useState } from "react";
import { RelatedParties } from "Components/Users/UserDetailPageContent/Components/RelatedParties";
import { Licenses } from "Components/Users/UserDetailPageContent/Components/Licenses";

type Props = {
  user: UserAdminDetailDto;
  verification: UserVerificationUpdateDto;
  party: PartyDto;
};

type TabItem = { label: string; content: ReactNode; isHidden?: boolean };

export const UserDetailPageContent = ({ user, verification, party }: Props) => {
  const [activeTab, setActiveTab] = useState(0);

  const isRelatedPartiesHidden = party.type !== PartyType.LegalEntity;
  const tabs: Array<TabItem> = [
    { label: "Uživatel", content: <UserForm user={user} /> },
    {
      label: "Osoba",
      content: (
        <PartyForm
          party={party}
          additionalIdentificationsSlot={
            user.hasIdentificationsUploaded && (
              <Box mb={5}>
                <DownloadIdentificationsButton
                  login={user.login}
                  userID={user.userID}
                />
              </Box>
            )
          }
        />
      ),
    },
    {
      label: "Licence",
      content: <Licenses licenses={party.licenses} />,
      isHidden: !user.accessRoleCodes.includes("Hunter"),
    },
    {
      label: "Ověření",
      content: <VerificationForm verification={verification} />,
    },
    {
      label: "Související osoby",
      content: <RelatedParties relatedParties={party.relatedParties} />,
      isHidden: isRelatedPartiesHidden,
    },
  ];
  const visibleTabs = tabs.filter(({ isHidden }) => !isHidden);

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={(_e, nextTab) => setActiveTab(nextTab)}
        variant="scrollable"
        scrollButtons="auto"
      >
        {visibleTabs.map(({ label }) => (
          <Tab key={label} label={label} />
        ))}
      </Tabs>
      <Box mt="0.5rem">
        {visibleTabs.map(({ content, label }, index) => (
          <div role="tabpanel" hidden={index !== activeTab} key={label}>
            {index === activeTab && content}
          </div>
        ))}
      </Box>
    </>
  );
};
