import styled, { css } from "styled-components";
import { Link, LinkProps } from "react-router-dom";
import { ArrowRightIcon } from "Components/Shared/Icons";

type Props = React.PropsWithoutRef<LinkProps> &
  React.RefAttributes<HTMLAnchorElement> & {
    isWithIcon?: boolean;
  };

const baseLinkStyles = css`
  text-decoration: inherit;
  color: ${props => props.theme.palette.primary.main};
  font-weight: 500;
`;

export const StyledHtmlLink = styled.a`
  ${baseLinkStyles}
`;

const StyledLink = styled(Link)`
  ${baseLinkStyles}
`;

const StyledWrapper = styled.div`
  a {
    display: flex;
    align-items: center;
  }

  svg {
    width: 16px;
    height: auto;
    margin-right: ${props => props.theme.spacing(1)};
  }
`;

export const UnstyledLink: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { isWithIcon, ...rest } = props;

  return isWithIcon ? (
    <StyledWrapper>
      <StyledLink {...rest}>
        <ArrowRightIcon></ArrowRightIcon>
        {props.children}
      </StyledLink>
    </StyledWrapper>
  ) : (
    <StyledLink {...rest} />
  );
};
