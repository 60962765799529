import { type FormData, SignInForm } from "Components/Auth/SignIn/SignInForm";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import type { RootStateType } from "State/Store";
import { Resources, useServerError } from "Translations/Resources";
import { resetError, setSignInStep } from "State/Auth/AuthReducer";
import { SignInStep } from "State/Auth/Models/AuthStateModels";
import { signInAsync } from "State/Auth/SignIn/SignInState";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";
import { useEffect, useState } from "react";

export const SignInPage = () => {
  const [keyToResetForm, toggleKeyToResetForm] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  const { translateError } = useServerError(
    Resources.SignIn.Error,
    Resources.SignIn.Error.General,
  );

  const {
    isLoading,
    errorCode,
    signInStep: step,
  } = useSelector((e: RootStateType) => e.auth);

  const login = new URLSearchParams(location.search).get("login") || "";

  const onSubmit = ({ login, password }: FormData) => {
    dispatch(
      signInAsync.request({
        model: { login, password },
        navigate,
      }),
    );
  };
  const onFormReset = () => {
    toggleKeyToResetForm(!keyToResetForm);
    dispatch(setSignInStep(SignInStep.Credentials));
  };

  return (
    <AuthPageLayout
      title={"Přihlášení"}
      isLoading={isLoading}
      pageType={"sign-in"}
      key={`${keyToResetForm}`}
    >
      <SignInForm
        step={step}
        isLoading={isLoading}
        onSubmit={onSubmit}
        serverError={translateError(errorCode)}
        onReset={onFormReset}
        login={login}
      />
    </AuthPageLayout>
  );
};

export default SignInPage;
