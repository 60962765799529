import { Typography } from "@mui/material";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { UserType } from "Hooks/useCurrentUserAccessRole";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resendVerificationEmailAsync } from "State/Auth/Verifications/ResendVerificationEmailState";
import { RootStateType } from "State/Store";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

const Wrapper = styled.div`
  margin: ${props => props.theme.spacing(4)};
`;

type Props = {
  userType?: UserType;
};

export const AwaitingEmailVerificationPage = ({ userType }: Props) => {
  const [wasResend, setWasResend] = useState(false);
  const { verificationEmail, user } = useSelector((e: RootStateType) => e.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const appUrl = getAppUrl();
      navigate(appUrl("dashboard"), { replace: true });
    }
  }, [user, navigate]);

  const resend = () => {
    if (verificationEmail && !wasResend) {
      dispatch(
        resendVerificationEmailAsync.request({
          email: verificationEmail,
        }),
      );
      setWasResend(true);
    }
  };

  return (
    <AuthPageLayout
      title={"Ověření e-mailu"}
      isLoading={false}
      pageType={"other"}
      userType={userType}
    >
      <Wrapper>
        <Typography fontSize={16}>Na Vaši e-mailovou adresu:</Typography>
        <br />
        {!!verificationEmail && (
          <Typography fontSize={16} fontWeight={600}>
            {verificationEmail}
          </Typography>
        )}
        <br />
        <Typography fontSize={16}>
          byl odeslán e-mail s aktivačním odkazem. Před pokračováním je nutné
          účet aktivovat kliknutím na odkaz v tomto e-mailu.
        </Typography>
        <br />
        <br />
        <Typography fontSize={16}>
          PS: Žádný e-mail nedorazil? Zkuste zkontrolovat složku SPAM nebo
          Hromadné. Případně si nechte poslat e-mail znovu.
        </Typography>
        <br />
        <PrimaryButton
          onClick={() => resend()}
          type="submit"
          fullWidth
          variant="contained"
          color="info"
          disabled={wasResend}
        >
          {wasResend ? "E-mail byl odeslán" : "Odeslat e-mail znovu"}
        </PrimaryButton>
      </Wrapper>
    </AuthPageLayout>
  );
};

export default AwaitingEmailVerificationPage;
