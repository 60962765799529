import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PARTY_LICENSE_QUERY_KEY } from "Api/Queries/Licenses/usePartyLicensesQuery";
import {
  CreateLicenseCommandResult,
  CreateLicenseRequest,
  postLicenses,
  LicenseDto,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: (license: LicenseDto) => void;
};

export const useCreateLicenseMutation = ({ onSuccess }: Props = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: processResponse((data: CreateLicenseRequest) =>
      postLicenses(data),
    ),
    onSuccess: (data: CreateLicenseCommandResult) => {
      queryClient.invalidateQueries({
        queryKey: [PARTY_LICENSE_QUERY_KEY],
      });
      onSuccess?.(data.license);
    },
  });
};
