import { Box } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

import mooneLogo from "Assets/Images/Images/moone.svg";
import legioneMooneLogo from "Assets/Images/Images/legione-moone.svg";
import { UserType } from "Hooks/useCurrentUserAccessRole";
import { ReactNode } from "react";

const USER_TYPE_TO_LOGO_IMG: Record<UserType, ReactNode> = {
  merchant: <img src={mooneLogo} alt="Moone" width="143" />,
  hunter: (
    <Box
      component="img"
      src={legioneMooneLogo}
      alt="Legi.one přináší Moone"
      sx={{ minWidth: "300px", maxWidth: "400px" }}
    />
  ),
  customer: <img src={mooneLogo} alt="Moone" width="143" />,
  admin: <img src={mooneLogo} alt="Moone" width="143" />,
};

const StyledWrapper = styled.div`
  display: flex;
  padding-bottom: ${props => props.theme.spacing(2)};
  padding-top: ${props => props.theme.spacing(3)};
`;

type UnauthenticatedHeaderProps = {
  userType?: UserType | null;
};

export const UnauthenticatedHeader = ({
  userType,
}: UnauthenticatedHeaderProps) => {
  const appUrl = getAppUrl();

  const logoImage = userType
    ? USER_TYPE_TO_LOGO_IMG[userType]
    : USER_TYPE_TO_LOGO_IMG.customer;

  return (
    <UnstyledLink to={appUrl("sign-in")}>
      <StyledWrapper>{logoImage}</StyledWrapper>
    </UnstyledLink>
  );
};
