import { LookupItem } from "Models/LookupItem";
import * as React from "react";
import styled from "styled-components";
import { Select, MenuItem, SelectChangeEvent, Box } from "@mui/material";
import { CheckboxInput } from "Components/Shared/Inputs/CheckboxInput";
import { ExpandArrowIcon } from "Components/Shared/Icons";

type valueType = string | number | string[] | number[];

type Props = {
  name: string;
  options: LookupItem[];
  value: valueType;
  defaultValue?: valueType;
  onChange?: (value: valueType) => void;
  onBlur?: () => void;
  disabled?: boolean;
  multiple?: boolean;
  label?: string;
  placeholder?: string;
  allSelectedText?: string;
  hasError?: boolean;
};

const StyledSelect = styled(Select)`
  &:before,
  &:after {
    content: none;
  }

  padding: 0;

  .MuiSelect-select {
    min-height: auto;
    border-radius: 0;
    background-color: #fff;
  }

  .MuiInputBase-input {
    padding: ${props => props.theme.spacing(3, 4)};
  }

  &:hover {
    .MuiSelect-select {
      border-color: ${props => props.theme.palette.text.primary};
    }
  }

  &.Mui-focused {
    .MuiSelect-select {
      border-color: ${props => props.theme.palette.primary.main};
    }
  }

  &.Mui-error {
    .MuiSelect-select {
      border-color: ${props => props.theme.palette.error.main};
    }
  }

  &.Mui-disabled {
    .MuiSelect-select {
      background-color: ${props => props.theme.colors.gray}80;
      border-color: ${props => props.theme.colors.gray}80;
      color: ${props => props.theme.palette.text.primary}80;
    }
  }
`;

const StyledLabel = styled.div`
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(1)};
`;

const StyledIcon = styled.div`
  position: absolute;
  right: 16px;
  pointer-events: none;

  svg {
    width: 18px;
    height: auto;
    opacity: 0.5;
  }
`;
const dropdownIcon = () => {
  return (
    <StyledIcon>
      <ExpandArrowIcon></ExpandArrowIcon>
    </StyledIcon>
  );
};

const StyledPlaceholder = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const StyledMenuItem = styled(MenuItem)`
  .MuiTouchRipple-root {
    color: ${props => props.theme.palette.primary.main};
  }

  .MuiCheckbox-root {
    padding: 0;
  }
`;

const StyledSelectedValue = styled.div<{ upperCase?: boolean }>`
  overflow: hidden;
  margin-right: 22px;
  text-overflow: ellipsis;
  text-transform: ${props => (props.upperCase ? "uppercase" : "none")};
  min-height: 16px;
  font-size: 16px;
  font-weight: 500;
`;

const selectedValue = (value: valueType, props: Props): React.ReactNode => {
  const { placeholder, multiple, options, allSelectedText } = props;

  if (!value || (value as Array<string | number>).length === 0) {
    return placeholder ? (
      <StyledPlaceholder>{placeholder}</StyledPlaceholder>
    ) : (
      <StyledSelectedValue></StyledSelectedValue>
    );
  }

  if (multiple && (value as Array<string | number>).length === options.length) {
    return (
      <StyledSelectedValue upperCase>{allSelectedText}</StyledSelectedValue>
    );
  }
  const text = multiple
    ? options
        .filter(o => (value as number[]).some(v => v === o.Value))
        .map(x => x.Name)
        .join(", ")
    : options.find(x => x.Value.toString() === value.toString())?.Name;

  return <StyledSelectedValue>{text}</StyledSelectedValue>;
};

export const Dropdown: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const {
    options,
    value,
    disabled,
    name,
    onChange,
    multiple,
    label,
    onBlur,
    hasError,
  } = props;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange && onChange(event.target.value as valueType);
  };

  return (
    <div>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledSelect
        value={value}
        defaultValue={props.defaultValue}
        onChange={handleChange}
        onBlur={onBlur}
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={dropdownIcon}
        disabled={disabled}
        multiple={multiple}
        error={hasError}
        fullWidth
        displayEmpty
        MenuProps={{
          variant: "menu",
        }}
        renderValue={value => selectedValue(value as valueType, props)}
        variant="outlined"
      >
        {options.map(opt => (
          <StyledMenuItem key={`${name}-${opt.Value}`} value={opt.Value}>
            {multiple ? (
              <CheckboxInput
                checked={(value as number[]).some(x => x === opt.Value)}
                label={opt.Name}
              />
            ) : (
              <Box sx={{ fontSize: "16px" }}>{opt.Name}</Box>
            )}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </div>
  );
};
