import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PARTY_LICENSE_QUERY_KEY } from "Api/Queries/Licenses/usePartyLicensesQuery";
import {
  UpdateLicensePaymentStatusCommandResult,
  UpdateLicensePaymentStatusRequest,
  putLicensesPublicIDPaymentStatus,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: (data: UpdateLicensePaymentStatusCommandResult) => void;
  onError?: () => void;
};

export const usePutLicensesPaymentStatusMutation = ({
  onSuccess,
  onError,
}: Props = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: processResponse(
      ({
        publicID,
        requestPayload,
      }: {
        publicID: string;
        requestPayload: UpdateLicensePaymentStatusRequest;
      }) => putLicensesPublicIDPaymentStatus(requestPayload, publicID),
    ),
    onSuccess: (data: UpdateLicensePaymentStatusCommandResult) => {
      queryClient.invalidateQueries({
        queryKey: [PARTY_LICENSE_QUERY_KEY],
      });
      onSuccess?.(data);
    },
    onError,
  });
};
