import { Control } from "react-hook-form";
import { IncomeInformationModel } from "Components/Onboarding/BusinessEntity/types";
import { CountryDropdown } from "Components/Shared/SelectorsForm/CountryDropdown";
import { PoliticallyExposedCheckbox } from "Components/Onboarding/Components/PoliticallyExposedCheckbox";
import {
  BusinessActivity,
  PartyType,
  SourceOfIncome,
} from "Infrastructure/Api/Api";
import { RelationTypeDropdown } from "Components/Shared/SelectorsForm/RelationTypeDropdown";
import { AverageTransactionValueDropdown } from "Components/Shared/SelectorsForm/AverageTransactionValueDropdown";
import { ExpectedMonthlyRevenueDropdown } from "Components/Shared/SelectorsForm/ExpectedMonthlyRevenueDropdown.";
import { SharedBusinessFields } from "Components/Onboarding/BusinessEntity/Steps/IncomeInformationStep/SharedBusinessFields";

type Props = {
  control: Control<IncomeInformationModel>;
  partyType?: PartyType;
  incomeSource?: SourceOfIncome;
  businessActivity?: BusinessActivity;
  isPoliticallyExposed: boolean;
};

export const MerchantIncomeInformationFields = ({
  control,
  partyType,
  incomeSource,
  businessActivity,
  isPoliticallyExposed,
}: Props) => (
  <>
    <SharedBusinessFields
      control={control}
      businessActivity={businessActivity}
      incomeSource={incomeSource}
    />
    <ExpectedMonthlyRevenueDropdown
      control={control}
      name="expectedMonthlyRevenue"
      label="Očekávaný měsíční objem"
    />
    <AverageTransactionValueDropdown
      control={control}
      name="averageTransactionValue"
      label="Průměrná hodnota jedné transakce"
    />
    <CountryDropdown
      control={control}
      name="taxResidence"
      label="Daňová rezidence"
    />
    {partyType === PartyType.LegalEntity && (
      <RelationTypeDropdown
        control={control}
        name="relationType"
        label="Vaše pozice"
        excludeBeneficialOwner
      />
    )}
    <PoliticallyExposedCheckbox
      control={control}
      name="isPoliticallyExposed"
      isChecked={isPoliticallyExposed}
    />
  </>
);
