import React from "react";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import type { RootStateType } from "State/Store";
import {
  getTransactionDetailAsync,
  resetTransactionDetail,
} from "State/Transactions/Detail/TransactionDetailState";
import { useResource } from "Translations/Resources";
import { getAppUrl } from "Utils/UrlUtils";
import { getTypeName } from "Utils/TransactionUtils";
import { TransactionDetail } from "Components/Transactions/TransactionDetail";

export const TransactionDetailPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const { t } = useResource();

  const { id } = useParams();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!!id) {
      dispatch(getTransactionDetailAsync.request(id));
    }

    return () => {
      dispatch(resetTransactionDetail());
    };
  }, [dispatch, id]);

  const { detail } = useSelector((e: RootStateType) => e.transaction);
  const [title, setTitle] = React.useState("Transakce");

  React.useEffect(() => {
    if (detail?.detail) {
      const left = getTypeName(t, detail.detail);
      const right =
        detail.detail.payoutOfferLinkOfferName ?? detail.detail.currencyName;
      setTitle(`${left} | ${right}`);
    }
  }, [detail, t]);

  const appUrl = getAppUrl();

  return (
    <AuthenticatedLayout title={title} goBackUrl={appUrl("transactions")}>
      <StyledPageWrapper>
        <div className="left-content">
          {id && <TransactionDetail publicID={id} />}
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default TransactionDetailPage;
