import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PARTY_LICENSE_QUERY_KEY } from "Api/Queries/Licenses/usePartyLicensesQuery";
import { deleteLicensesLicensePublicID } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: () => void;
};

export const useDeleteLicenseMutation = ({ onSuccess }: Props = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: processResponse((publicID: string) =>
      deleteLicensesLicensePublicID(publicID),
    ),
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PARTY_LICENSE_QUERY_KEY],
      });
    },
  });
};
