import { FormEvent } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LicensePaymentStatus, PartyLicenseDto } from "Infrastructure/Api/Api";
import { toast } from "sonner";
import { StyledForm } from "Components/Users/UserDetailPageContent/Components/StyledForm";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { LicenseFormFields } from "Components/Users/UserDetailPageContent/Components/LicenseFormFields";
import { usePutLicensesMutation } from "Api/Mutations/Licenses/usePutLicenses";
import { z } from "zod";

const schema = z.object({
  paymentStatus: z.nativeEnum(LicensePaymentStatus),
  licenseNo: z.string().nullish(),
});

type LicenseFormProps = {
  license: PartyLicenseDto;
};

export const LicenseForm = ({ license }: LicenseFormProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<PartyLicenseDto>({
    resolver: zodResolver(schema),
    defaultValues: license,
  });

  const { mutate: updateLicensePaymentStatus, isPending } =
    usePutLicensesMutation({
      onSuccess: data => {
        reset({ ...license, ...data.partyLicenseDto });
        toast.success("Uložení údajů licence proběhlo úspěšně");
      },
      onError: () =>
        toast.error("Při ukládání údajů licence se vyskytla chyba"),
    });

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(data => {
      updateLicensePaymentStatus({
        publicID: license.publicID,
        requestPayload: {
          paymentStatus: data.paymentStatus,
          licenseNo: data.licenseNo,
        },
      });
    })();
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <PrimaryPaper>
        <LicenseFormFields control={control} />
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={!isDirty}
          isLoading={isPending}
          variant="contained"
          color="primary"
        >
          Uložit
        </PrimaryButton>
      </PrimaryPaper>
    </StyledForm>
  );
};
