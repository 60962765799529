import { useCreateLicenseMutation } from "Api/Mutations/Licenses/useCreateLicenseMutation";
import { useDeleteLicenseMutation } from "Api/Mutations/Licenses/useDeleteLicenseMutation";
import { usePutLicensesPaymentStatusMutation } from "Api/Mutations/Licenses/usePutLicensesPaymentStatus";
import { useOCR } from "Api/Mutations/Onboarding/useOCR";
import { useCreatePartyRelationMutation } from "Api/Mutations/Party/useCreatePartyRelationMutation";
import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { useUpdateVerificationsMutation } from "Api/Mutations/Verifications/useUpdateVerificationsMutation";
import { useVerificationsMutation } from "Api/Mutations/Verifications/useVerificationsMutation";
import { usePartyLicensesQuery } from "Api/Queries/Licenses/usePartyLicensesQuery";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { useVerificationsQuery } from "Api/Queries/Verifications/useVerificationsQuery";
import { useRelatedParty } from "Components/Onboarding/BusinessEntity/Hooks/useRelatedParty";
import {
  LicenseSelectionModel,
  FormModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { getMappedAresToForm } from "Components/Onboarding/BusinessEntity/Utils/aresUtils";
import { getFormDataFromOCR } from "Components/Onboarding/BusinessEntity/Utils/ocrUtils";
import {
  getBusinessPartyDataFromForm,
  getInitialFormDataFromParty,
  getPartyDataFromForm,
} from "Components/Onboarding/BusinessEntity/Utils/partyUtils";
import { getFormDataFromVerifications } from "Components/Onboarding/BusinessEntity/Utils/verificationUtils";
import { useBankingAuth } from "Hooks/useBankingAuth";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import {
  PartyType,
  OwnershipType,
  PartyDto,
  RelationType,
  BankEnvironment,
  LicensePaymentStatus,
  IdentityVerificationStatus,
} from "Infrastructure/Api/Api";
import { logError } from "Infrastructure/Utils/LoggingUtils";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "sonner";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";
import { DeepPartial } from "types";
import { appUrl } from "Utils/UrlUtils";

const LIFETIME_PERIODICITY = null;

type GetFormValues = <T extends OnboardingSteps>(
  step: T,
) => Partial<FormModel[T]>;

type ActingPersonParty = {
  publicID?: string;
  relatedPublicID?: string;
};

type LicenseManualPaymentDetails = {
  amount: number;
  iban: string;
  accountNumber: string;
  reference: string;
  currencyCode: string;
  note: string;
};

type OnboardingContextType = {
  step: OnboardingSteps;
  nextStep: (values: unknown) => void;
  prevStep: () => void;
  setFormValues: (step: OnboardingSteps, values: unknown) => void;
  getFormValues: GetFormValues;
  setAresData: (aresData: PartyDto | null) => void;
  hasPrevStep: boolean;
  isLoadingNextStep: boolean;
  isLoadingOnboarding: boolean;
  licenseManualPaymentDetails: LicenseManualPaymentDetails | null;
};

const OnboardingContext = createContext<OnboardingContextType>(
  {} as OnboardingContextType,
);

type OnboardingContextProviderProps = {
  children: ReactNode;
  isHunter: boolean;
};

export const OnboardingContextProvider = ({
  children,
  isHunter = false,
}: OnboardingContextProviderProps) => {
  const [step, setStep] = useState<OnboardingSteps>(0); // OnboardingSteps.BusinessInformation,
  const [initialLoading, setInitialLoading] = useState(true);
  const [actingPersonParty, setActingPersonParty] =
    useState<ActingPersonParty>();
  const [licenseIDs, setLicenseIDs] = useState<{
    publicID: string;
    transactionPublicID: string;
  }>({
    publicID: "",
    transactionPublicID: "",
  });

  const [licenseManualPaymentDetails, setLicenseManualPaymentDetails] =
    useState<LicenseManualPaymentDetails | null>(null);

  const actingPersonIsOwner = useRef(false);
  const formValues = useRef<DeepPartial<FormModel>>({
    [OnboardingSteps.AdditionalInformation]: {
      isOwner: true,
    },
  });

  const user = useLoggedInUser();
  const navigate = useNavigate();

  const { data: partyData } = usePartyQuery(user?.partyPublicID);
  const { data: partyLicenses } = usePartyLicensesQuery(user?.partyPublicID);
  const { data: verificationData } = useVerificationsQuery();
  const { data: actingPersonPartyData, isLoading: isLoadingActingPersonParty } =
    usePartyQuery(actingPersonParty?.publicID);

  useEffect(
    function loadInitialFormValues() {
      if (!initialLoading) {
        return;
      }

      // replace only if the form is empty
      if (partyData) {
        const { formData, actingPerson } = getInitialFormDataFromParty(
          partyData,
          formValues.current ?? {},
        );
        formValues.current = formData;

        setActingPersonParty({
          relatedPublicID: actingPerson.relatedPartyPublicID,
          publicID: actingPerson.partyPublicID,
        });

        if (actingPerson?.isOwner) {
          actingPersonIsOwner.current = true;
        }
      }

      if (verificationData) {
        formValues.current = getFormDataFromVerifications(
          verificationData,
          formValues.current ?? {},
        );
      }

      if (verificationData && partyData) {
        setInitialLoading(false);
      }
    },
    [partyData, verificationData, initialLoading],
  );

  const { mutate: putParty, isPending: isPartyMutating } = usePartyMutation({
    onSuccess: () => {
      const isLegalEntity =
        formValues.current[OnboardingSteps.BusinessInformation]?.partyType ===
        PartyType.LegalEntity;

      if (step === OnboardingSteps.UserDetail && !isLegalEntity) {
        // When type is not legal entity, we need to skip additional information step
        const nextStep = isHunter
          ? OnboardingSteps.LicenseSelection
          : OnboardingSteps.BankAccountConnection;
        setStep(nextStep);
      } else {
        setStep(step + 1);
      }
    },
  });

  const { updateRelatedParty, isPending: isRelatedPartyUpdating } =
    useRelatedParty({
      legalEntityPartyID: user?.partyPublicID!,
      partyPublicID: actingPersonParty?.publicID,
      relatedPartyPublicID: actingPersonParty?.relatedPublicID,
      onSuccessUpdate: ({ relatedPartyPublicID, partyPublicID }) => {
        setActingPersonParty({
          publicID: partyPublicID,
          relatedPublicID: relatedPartyPublicID,
        });
        // we can skip documents upload if we have already identification number (the user document was already uploaded)
        if (actingPersonPartyData?.identificationNumber) {
          formValues.current = getFormDataFromOCR(
            actingPersonPartyData,
            formValues.current ?? {},
          );
          setStep(OnboardingSteps.UserDetail);
        } else {
          setStep(OnboardingSteps.PersonalDocument);
        }
      },
    });

  const { mutate: postPartyRelation, isPending: isPartyRelationCreating } =
    useCreatePartyRelationMutation({
      onSuccess: () => {
        actingPersonIsOwner.current = true;
        if (isHunter) {
          setStep(OnboardingSteps.LicenseSelection);
        } else {
          setStep(step + 1);
        }
      },
    });

  const { mutate: putVerification, isPending: isVerificationMutating } =
    useUpdateVerificationsMutation({
      onSuccess: () => {
        if (
          formValues.current[OnboardingSteps.BusinessInformation]?.partyType !==
          PartyType.LegalEntity
        ) {
          setStep(step + 1);
          return;
        }

        updateRelatedParty({
          relationType:
            formValues.current[OnboardingSteps.IncomeInformation]
              ?.relationType!,
        });
      },
    });

  const { mutateAsync: ocrRequest, isPending: isProcessingOcr } = useOCR({
    onSuccess: ({ data }) => {
      if (!data?.publicID) {
        toast.error("Chyba při zpracování dokumentů");
        logError(new Error("OCR failed - publicID missing"));
      }

      if (data) {
        formValues.current = getFormDataFromOCR(data, formValues.current ?? {});
      }

      setStep(step + 1);
    },
    onError: () => {
      toast.error("Údaje z dokumentů se nepodařilo přečíst");
      setStep(step + 1);
    },
  });

  const dispatch = useDispatch();
  const { mutate: verifyUser } = useVerificationsMutation({
    onSuccess: () => {
      dispatch(getUserInfoAsync.request(undefined));
      navigate(appUrl("dashboard"));
    },
  });

  const { mutateAsync: deleteLicense, isPending: isDeleteLicensePending } =
    useDeleteLicenseMutation();

  const {
    mutate: updateLicensePaymentStatus,
    isPending: isUpdateLicensePaymentStatusPending,
  } = usePutLicensesPaymentStatusMutation();

  const { mutate: createLicense, isPending: isCreateLicensePending } =
    useCreateLicenseMutation({
      onSuccess: ({ publicID, transaction }) => {
        setLicenseIDs({
          publicID: publicID,
          transactionPublicID: transaction.publicID,
        });
        setLicenseManualPaymentDetails({
          amount: transaction.amount,
          iban: transaction.receiverIBAN,
          reference: transaction.paymentReference,
          currencyCode: transaction.currencyCode,
          note: transaction.noteForDebtor,
          accountNumber: transaction.receiverBankAccount,
        });
      },
    });

  const { isPending: isAuthorizingPayment, authorize: authorizePayment } =
    useBankingAuth();

  const isLoadingNextStep =
    isPartyMutating ||
    isVerificationMutating ||
    isLoadingActingPersonParty ||
    isRelatedPartyUpdating ||
    isPartyRelationCreating ||
    isProcessingOcr ||
    isCreateLicensePending ||
    isAuthorizingPayment ||
    isUpdateLicensePaymentStatusPending ||
    isDeleteLicensePending;

  const getFormValues: GetFormValues = step => formValues.current[step] || {};
  const setFormValues = (step: OnboardingSteps, values: unknown = {}) => {
    formValues.current = {
      ...formValues.current,
      [step]: values,
    };
  };

  const nextStep = async (values: unknown) => {
    if (isLoadingNextStep) {
      return;
    }

    setFormValues(step, values);

    if (step === OnboardingSteps.BusinessInformation) {
      putParty({
        publicID: user?.partyPublicID!,
        data: getBusinessPartyDataFromForm(formValues.current, partyData!),
      });
    } else if (step === OnboardingSteps.IncomeInformation) {
      const {
        businessActivity,
        otherBusinessActivityDetails,
        averageTransactionValue,
        expectedMonthlyRevenue,
        incomeSource,
        isPoliticallyExposed,
        taxResidence,
        incomeSourceDetail,
      } = formValues.current[OnboardingSteps.IncomeInformation] ?? {};
      putVerification({
        businessActivity,
        otherBusinessActivityDetails,
        averageTransactionValue,
        expectedMonthlyRevenue,
        taxResidencyCountryID: taxResidence,
        sourceOfIncome: incomeSource,
        otherSourceOfIncomeDetails: incomeSourceDetail,
        isPoliticallyExposedPerson: !!isPoliticallyExposed,
      });
    } else if (step === OnboardingSteps.ManualAccountVerification) {
      navigate(appUrl("dashboard"));
    } else if (step === OnboardingSteps.PersonalDocument) {
      if (
        verificationData?.identityVerificationStatus ===
        IdentityVerificationStatus.Processing
      ) {
        setStep(step + 1);
      } else {
        const documents = formValues.current[OnboardingSteps.PersonalDocument];
        ocrRequest({
          documents: {
            firstIDCardFrontSide: documents!.firstDocument!.frontSide as File,
            firstIDCardBackSide: documents!.firstDocument!.backSide as File,
            secondIDCardFrontSide: documents!.secondDocument!.frontSide as File,
          },
          partyPublicID:
            actingPersonParty?.publicID || (user!.partyPublicID as string),
        });
      }
    } else if (step === OnboardingSteps.UserDetail) {
      putParty({
        publicID: actingPersonParty?.publicID || user?.partyPublicID!,
        data: getPartyDataFromForm(
          formValues.current as FormModel,
          actingPersonPartyData ? actingPersonPartyData : partyData!,
        ),
      });
    } else if (step === OnboardingSteps.AdditionalInformation) {
      const nextStep = isHunter ? OnboardingSteps.LicenseSelection : step + 1;
      if (
        actingPersonParty?.publicID &&
        formValues.current[OnboardingSteps.AdditionalInformation]?.isOwner &&
        !actingPersonIsOwner.current
      ) {
        postPartyRelation({
          partyPublicID: user?.partyPublicID!,
          data: {
            relatedPartyPublicID: actingPersonParty.publicID,
            relationType: RelationType.BeneficialOwner,
          },
        });
      } else if (
        actingPersonIsOwner.current &&
        !formValues.current[OnboardingSteps.AdditionalInformation]?.isOwner
      ) {
        // TODO: remove relation for action person as owner
        setStep(nextStep);
      } else {
        setStep(nextStep);
      }
    } else if (step === OnboardingSteps.LicenseSelection) {
      const licenseData = formValues.current[
        OnboardingSteps.LicenseSelection
      ] as LicenseSelectionModel;

      const existingUnpaidLicenses = partyLicenses?.items?.filter(
        ({ paymentStatus }) => paymentStatus === LicensePaymentStatus.Initiated,
      );

      if (existingUnpaidLicenses?.length) {
        await Promise.all(
          existingUnpaidLicenses.map(license =>
            deleteLicense(license.publicID),
          ),
        );
      }

      createLicense({
        countryAlpha2Code: licenseData.countryAlpha2Code,
        code: licenseData.license,
        walletAddress: licenseData.privateWalletAddress || "",
        ownershipType: licenseData.isUsingPrivateWallet
          ? OwnershipType.NonCustodial
          : OwnershipType.Custodial,
        subscriptionPeriodicity: LIFETIME_PERIODICITY,
      });

      setStep(step + 1);
    } else if (step === OnboardingSteps.LicensePayment) {
      const bankEnvironment =
        formValues.current[OnboardingSteps.LicensePayment]?.environment;

      if (bankEnvironment) {
        authorizePayment({
          environment: bankEnvironment as BankEnvironment,
          transactionPublicID: licenseIDs.transactionPublicID,
        });
        updateLicensePaymentStatus({
          publicID: licenseIDs.publicID,
          requestPayload: { paymentStatus: LicensePaymentStatus.Processing },
        });
      } else {
        setStep(step + 1);
      }
    } else if (step === OnboardingSteps.ManualLicensePayment) {
      verifyUser();
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    const isLegalEntity =
      formValues.current[OnboardingSteps.BusinessInformation]?.partyType ===
      PartyType.LegalEntity;

    let previousStep =
      step === OnboardingSteps.BankAccountConnection && !isLegalEntity
        ? OnboardingSteps.UserDetail
        : step - 1;

    // hunters need to skip bank account steps
    if (step === OnboardingSteps.LicenseSelection) {
      previousStep = isLegalEntity
        ? OnboardingSteps.AdditionalInformation
        : OnboardingSteps.UserDetail;
    }

    setStep(Math.max(0, previousStep));
  };

  const setAresData = (aresData: PartyDto | null) => {
    if (!aresData) {
      return;
    }

    formValues.current = getMappedAresToForm(aresData, formValues.current);
  };
  return (
    <OnboardingContext.Provider
      value={{
        step,
        nextStep,
        prevStep,
        getFormValues,
        setFormValues,
        setAresData,
        hasPrevStep: step > OnboardingSteps.BusinessInformation,
        isLoadingNextStep,
        isLoadingOnboarding: initialLoading,
        licenseManualPaymentDetails,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);
