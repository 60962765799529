import { useRef } from "react";
import { useCarouselManager } from "./hooks/useCarouselManager";
import { CarouselContainer } from "./Components/CarouselContainer";
import { CarouselInnerContainer } from "./Components/CarouselInnerContainer";
import { CarouselTrack } from "./Components/CarouselTrack";
import { CarouselSlide } from "./Components/CarouselSlide";
import { CarouselDots } from "./Components/CarouselDots";
import { CarouselTitle } from "./Components/CarouselTitle";
import { DEFAULT_NEXT_SLIDE_THRESHOLD } from "Components/Shared/Inputs/CarouselPicker/constants";
import { CarouselOption } from "Components/Shared/Inputs/CarouselPicker/types";

type CarouselPickerProps<TValue = string> = {
  options: ReadonlyArray<CarouselOption<TValue>>;
  defaultValue: TValue;
  isInfinite?: boolean;
  nextSlideThreshold?: number;
  onSelect?: (option: CarouselOption<TValue>) => void;
  isDisabled?: boolean;
};

export const CarouselPicker = <TValue extends string>({
  options,
  onSelect,
  defaultValue,
  isInfinite,
  isDisabled,
  nextSlideThreshold = DEFAULT_NEXT_SLIDE_THRESHOLD,
}: CarouselPickerProps<TValue>) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const {
    currentIndex,
    dragDistance,
    hasDragged,
    trackEventHandlers,
    updateCurrentIndex,
  } = useCarouselManager({
    options,
    defaultValue,
    isInfinite,
    nextSlideThreshold,
    onSelect,
    carouselRef,
  });

  return (
    <CarouselContainer>
      <CarouselInnerContainer>
        <CarouselTrack
          ref={carouselRef}
          currentIndex={currentIndex}
          dragDistance={dragDistance}
          height={366}
          isDisabled={isDisabled}
          {...trackEventHandlers}
        >
          {options.map((option, index) => (
            <CarouselSlide
              key={option.value}
              option={option}
              isDisabled={isDisabled}
              index={index}
              currentIndex={currentIndex}
              hasDragged={hasDragged}
              onSelect={updateCurrentIndex}
            />
          ))}
        </CarouselTrack>
        <CarouselDots
          totalOptions={options.length}
          currentIndex={currentIndex}
        />
        <CarouselTitle>{options[currentIndex].title}</CarouselTitle>
      </CarouselInnerContainer>
    </CarouselContainer>
  );
};
