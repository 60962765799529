import { useMutation } from "@tanstack/react-query";
import {
  CreatePartyCommandResult,
  CreatePartyDto,
  postParties,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: (publicID: string) => void;
};

export const useCreatePartyMutation = ({ onSuccess }: Props = {}) =>
  useMutation({
    mutationFn: processResponse((data: CreatePartyDto = {}) => postParties(data)),
    onSuccess: (data: CreatePartyCommandResult) => {
      onSuccess?.(data.publicID);
    },
  });
