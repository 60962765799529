import { useQuery } from "@tanstack/react-query";
import { getLeadsAffiliateLink } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";


export const AFFILIATE_LINK_QUERY_KEY = ["AFFILIATE_LINK"];

export const useGetAffiliateLink = () =>
  useQuery({
    queryKey: AFFILIATE_LINK_QUERY_KEY,
    queryFn: processResponse(() => getLeadsAffiliateLink()),
  });

