import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { BottomApplicationLink } from "Components/Layout/Menu/BottomMenuItem";
import {
  ForwardArrowIcon,
  HomeIcon,
  MerchantAccountIcon,
  MoreIcon,
  SortingArrowsIcon,
} from "Components/Shared/Icons";
import { AccessRightCodes } from "Models/AccessRightCodes";
import { Resources, useResource } from "Translations/Resources";
import { appUrl } from "Utils/UrlUtils";

const ICON_SIZE = 16;

const MenuItemsResources = Resources.Menu.BottomMenuItems;

export type BottomApplicationLinkWithAccessCode = BottomApplicationLink & {
  accessRightCode: (typeof AccessRightCodes)[keyof typeof AccessRightCodes];
};

export const useBottomMenuLinks = () => {
  const { t } = useResource();
  const { data: accounts } = useBankingAccounts();

  const links = {
    overview: {
      label: t(MenuItemsResources.Overview),
      value: appUrl("dashboard"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <HomeIcon height={ICON_SIZE} />,
    },
    transactions: {
      label: t(MenuItemsResources.History),
      value: appUrl("transactions"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <SortingArrowsIcon height={ICON_SIZE} />,
    },
    customerPaymentPay: {
      label: t(MenuItemsResources.ActionButton),
      value: appUrl("customer-payment-pay"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <ForwardArrowIcon height={ICON_SIZE} />,
      isAction: true,
      disabled: accounts?.items?.length === 0,
    },
    bankAccounts: {
      label: t(MenuItemsResources.Accounts),
      value: appUrl("bank-address-list"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <MerchantAccountIcon height={ICON_SIZE} />,
    },
    settings: {
      label: t(MenuItemsResources.Settings),
      value: appUrl("settings"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <MoreIcon height={ICON_SIZE} />,
    },
  } satisfies Record<string, BottomApplicationLinkWithAccessCode>;

  return links;
};
