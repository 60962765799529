import { Grid, Typography } from "@mui/material";
import { useVerificationsQuery } from "Api/Queries/Verifications/useVerificationsQuery";
import { Colors } from "Components/Layout/Themes/Colors";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

export const LimitedAccountPopup = () => {
  const { data } = useVerificationsQuery();

  const isVisible = data?.verificationLevel === 1;

  return (
    <>
      {isVisible && (
        <div>
          <PrimaryPaper>
            <Typography
              color={Colors.Red}
              align="left"
              fontWeight={600}
              marginBottom={3}
            >
              {"Omezený účet"}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography align="left" color={Colors.Red}>
                  {
                    "Vámi zadané informace je nutné ověřit našim pracovníkem. Jakmile bude vše ověřeno, budeme Vás informovat."
                  }
                </Typography>
              </Grid>
            </Grid>
          </PrimaryPaper>
        </div>
      )}
    </>
  );
};
