import { Box, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { usePartyLicensesQuery } from "Api/Queries/Licenses/usePartyLicensesQuery";
import { LicensePaymentStatus } from "Infrastructure/Api/Api";
import { appUrl } from "Utils/UrlUtils";
import { useNavigate } from "react-router";

export const NoValidLicensePopup = () => {
  const currentUser = useLoggedInUser();
  const navigate = useNavigate();

  const { data, isLoading } = usePartyLicensesQuery(currentUser?.partyPublicID);

  if (isLoading) {
    return null;
  }

  const hasValidLicense = data?.items.some(
    license => license.paymentStatus === LicensePaymentStatus.Success,
  );

  const transactionPublicID = data?.items[0]?.transactionPublicID || "";

  //this should not normally happen, either the user has a valid license, or at least an unpaid one assigned
  if (!hasValidLicense && !transactionPublicID) {
    return (
      <Box>
        <PrimaryPaper sx={{ border: `1px solid ${Colors.Red}` }}>
          <Typography
            color={Colors.Red}
            align="left"
            fontWeight={600}
            marginBottom={3}
          >
            Omezený účet
          </Typography>
          <Typography align="left" color={Colors.Red}>
            Nemáte přiřazenou žádnou platnou licenci.
          </Typography>
        </PrimaryPaper>
      </Box>
    );
  }

  if (!hasValidLicense) {
    return (
      <Box>
        <PrimaryPaper sx={{ border: `1px solid ${Colors.Red}` }}>
          <Typography
            color={Colors.Red}
            align="left"
            fontWeight={600}
            marginBottom={3}
          >
            Omezený účet
          </Typography>
          <Box display="flex" flexDirection="column" gap={5}>
            <Typography align="left" color={Colors.Red}>
              Účet bude aktivován po připsání platby za licenci. Pokud máte již
              zaplaceno, vyčkejte na připsání peněz.
            </Typography>
            <Box display="flex" width={"100%"} gap={4}>
              <PrimaryButton
                fullWidth
                type="button"
                color="primary"
                onClick={() =>
                  navigate(
                    `${appUrl("customer-payment-pay")}/${transactionPublicID}`,
                  )
                }
              >
                Platba přes připojený účet
              </PrimaryButton>
              <PrimaryButton
                fullWidth
                type="button"
                color="info"
                variant="outlined"
                onClick={() =>
                  navigate(
                    `${appUrl(
                      "initiate-payment-detail",
                    )}/${transactionPublicID}`,
                  )
                }
              >
                QR Platba
              </PrimaryButton>
            </Box>
          </Box>
        </PrimaryPaper>
      </Box>
    );
  }

  return null;
};
