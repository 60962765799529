import { Typography } from "@mui/material";
import { EntityListOfUserDto } from "../../Infrastructure/Api/Api";
import { PrimaryDataTable } from "Components/Shared/PrimaryDataTable/PrimaryDataTable";
import { useNavigate } from "react-router-dom";
import { getAppUrl } from "Utils/UrlUtils";
import { getCell } from "Components/Users/utils";

type UserListTableProps = {
  list: EntityListOfUserDto | null;
  isLoading: boolean;
  rowsPerPage: number;
  onPageChange: (page: number, rows: number) => void;
};

const columns = [
  { label: "Login", id: "login" },
  { label: "Jméno", id: "firstName" },
  { label: "Příjmení", id: "lastName" },
  { label: "Firma", id: "companyName" },
  {
    label: "Ověření",
    id: "verificationAdditionalInfoStatus",
  },
  { label: "Datum vytvoření", id: "dateCreated" },
  { label: "Vs. platby licence", id: "userID" }, // This better be only temporary
] as const;

export const UserListTable = ({
  isLoading,
  list,
  rowsPerPage,
  onPageChange,
}: UserListTableProps) => {
  const navigate = useNavigate();
  const appUrl = getAppUrl();

  const rows =
    list?.items.map(user => ({
      data: {
        ...user,
        ...user.party,
      },
    })) ?? [];

  return (
    <PrimaryDataTable
      rows={rows}
      rowEntityID={"userID"}
      columns={columns}
      getCell={getCell}
      getHeaderCell={({ label }) => (
        <Typography fontWeight={600}>{label}</Typography>
      )}
      totalCount={list?.totalCount}
      onPageChange={onPageChange}
      isLoading={isLoading}
      rowsPerPage={rowsPerPage}
      onRowClick={userDto =>
        navigate(`${appUrl("user-detail")}/${userDto.userID}`)
      }
    />
  );
};
