import { BusinessActivity, SourceOfIncome } from "Api/Api";
import { IncomeInformationModel } from "Components/Onboarding/BusinessEntity/types";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { BusinessActivityDropdown } from "Components/Shared/SelectorsForm/BusinessActivityDropdown";
import { IncomeSourceDropdown } from "Components/Shared/SelectorsForm/IncomeSourceDropdown";
import { Control } from "react-hook-form";

type Props = {
  control: Control<IncomeInformationModel>;
  businessActivity?: BusinessActivity;
  incomeSource?: SourceOfIncome;
};

export const SharedBusinessFields = ({
  control,
  businessActivity,
  incomeSource,
}: Props) => {
  const isOtherBusinessActivity = businessActivity === BusinessActivity.Other;
  const isOtherSourceOfIncome = incomeSource === SourceOfIncome.Other;

  return (
    <>
      <BusinessActivityDropdown
        control={control}
        name="businessActivity"
        label="Předmět podnikání"
      />
      {isOtherBusinessActivity && (
        <FormInput
          control={control}
          name="otherBusinessActivityDetails"
          label="Upřesněte předmět podnikání"
        />
      )}
      <IncomeSourceDropdown
        control={control}
        name="incomeSource"
        label="Zdroj příjmů"
      />
      {isOtherSourceOfIncome && (
        <FormInput
          control={control}
          name="incomeSourceDetail"
          label="Upřesněte zdroj příjmů"
        />
      )}
    </>
  );
};
