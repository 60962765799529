import {
  FormModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { getCompanyName } from "Components/Onboarding/BusinessEntity/Utils/partyUtils";
import { PartyDto } from "Infrastructure/Api/Api";
import { DeepPartial } from "types";

export const getMappedAresToForm = (
  party: PartyDto,
  formData: DeepPartial<FormModel>,
): DeepPartial<FormModel> => {
  const address = party.addresses?.find(a => a.type === "Headquarters");

  const formBusinessInformation = formData[OnboardingSteps.BusinessInformation];

  return {
    ...formData,
    [OnboardingSteps.BusinessInformation]: {
      ...formBusinessInformation,
      address: address
        ? {
            ...formBusinessInformation?.address,
            city: address.municipality,
            postalCode: address.postalCode ?? undefined,
            street: address.street ?? formBusinessInformation?.address?.street,
            descriptionNumber:
              address.streetNumber ??
              formBusinessInformation?.address?.descriptionNumber,
            orientationNumber:
              address.orientationNumber ??
              formBusinessInformation?.address?.orientationNumber,
          }
        : undefined,
      identificationNumber:
        party.companyNumber ?? formBusinessInformation?.identificationNumber,
      partyType: party.type,
      companyName:
        getCompanyName(party) ?? formBusinessInformation?.companyName,
    },
  };
};
