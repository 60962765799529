import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { Resources, useResource } from "Translations/Resources";
import { Typography } from "@mui/material";
import { QrCodePayment } from "Components/Shared/Payments/QrCodePayment";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { useVerificationsMutation } from "Api/Mutations/Verifications/useVerificationsMutation";
import { useDispatch } from "react-redux";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";
import { getPaymentDescriptor } from "Utils/BankUtils";

const StepResources =
  Resources.Onboarding.BusinessOnboarding.Steps.AccountValidation; //TODO: this should come from a more generic source, as it's already used in multiple places other than account validation

export const ManualLicensePaymentStep = () => {
  const { t } = useResource();
  const dispatch = useDispatch();
  const { licenseManualPaymentDetails } = useOnboardingContext();
  const { mutate, isPending } = useVerificationsMutation({
    onSuccess: () => {
      dispatch(getUserInfoAsync.request(undefined));
    },
  });

  if (!licenseManualPaymentDetails) {
    return <>Omlouváme se, ale něco se pokazilo, zkuste to prosím znovu později.</>;
  }

  const { reference, accountNumber, iban, amount, currencyCode, note } =
    licenseManualPaymentDetails;

  const paymentDescriptor = getPaymentDescriptor(
    iban,
    reference,
    amount,
    currencyCode,
    note,
  );

  return (
    <FullHeightForm>
      <StepBox
        submitButtonLabel="Odesláno"
        onSubmitClick={mutate}
        isSubmitting={isPending}
      >
        <Typography mb={5} fontSize="1rem">
          {"Platba license"}
        </Typography>
        <QrCodePayment
          amount={amount}
          note={note}
          paymentReference={reference}
          accountNumber={accountNumber}
          paymentDescriptor={paymentDescriptor}
          qrInstructions={t(StepResources.QRInstructions)}
        />
        <Typography marginTop={6}>
          {t(Resources.Transactions.BankTransferInfo)}
        </Typography>
      </StepBox>
    </FullHeightForm>
  );
};
