import { Box } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { SettingsRowButton } from "Components/Settings/SettingsRowButton";
import { SettingsRowLink } from "Components/Settings/SettingsRowLink";
import {
  FriendsIcon,
  InstagramVerificationBadgeIcon,
  LogoutRoundedIcon,
  MultiplyIcon,
  TrashBinIcon,
  UserIcon,
} from "Components/Shared/Icons";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getLeadListAsync } from "State/Afl/LeadListState/LeadListState";
import { resetUser } from "State/Auth/AuthReducer";
import { getAppUrl } from "Utils/UrlUtils";

export const SettingsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLeadListAsync.request());

    return () => {
      dispatch(getLeadListAsync.success(null));
    };
  }, [dispatch]);

  const appUrl = getAppUrl();

  return (
    <AuthenticatedLayout title="Více">
      <StyledPageWrapper>
        <Box
          className="left-content"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap={4}
          height="100%"
        >
          {/* <UserLimits /> */}
          <div>
            <SettingsRowLink
              name="Profil"
              url={appUrl("account")}
              icon={<UserIcon />}
            />
            {/*<SettingsRowLink
                name="Ověření účtu"
                url={appUrl("verification")}
                icon={<VerificationBadgeIcon />}
              />*/}
            {/* <SettingsRowLink
                name="Uchování bitcoinů"
                url={appUrl("account-settings")}
                icon={<MoneyBoxIcon />}
              /> */}
            <SettingsRowLink
              name="Připojené účty"
              url={appUrl("bank-address-list")}
              icon={<InstagramVerificationBadgeIcon />}
            />
            {/* <SettingsRowLink
              name="Notifikace a upozornění"
              url={appUrl("notification-settings")}
              icon={<NotificationIcon />}
            /> */}
            <SettingsRowLink
              name="Pozvat přítele"
              url={appUrl("affiliate")}
              icon={<FriendsIcon />}
            />
            <SettingsRowButton
              name="Odhlásit se"
              icon={<LogoutRoundedIcon width="16px" height="16px" />}
              onClick={() => {
                dispatch(resetUser({ isPageReloadDisabled: true }));
                navigate(appUrl("sign-in"));
              }}
            />
          </div>
          <SettingsRowLink
            isDestructiveAction
            name="Smazat účet"
            url={appUrl("deactivate-user")}
            icon={<TrashBinIcon />}
            rightIcon={
              <MultiplyIcon
                style={{ marginLeft: "5px" }}
                width={12}
                height={12}
              />
            }
          />
        </Box>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default SettingsPage;
