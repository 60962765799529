import { getType } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { resetUser, setUnauthenticatedUrl } from "State/Auth/AuthReducer";

function* resetUserFn(action: ReturnType<typeof resetUser>): Generator {
  window.onbeforeunload = null;
  localStorage.clear();
  yield put(setUnauthenticatedUrl(action.payload.authRedirectUrl ?? null));
  //Do not refresh page if user is already signed out.
  if (!action.payload.authRedirectUrl && !action.payload.isPageReloadDisabled) {
    window.setTimeout(() => window.location.reload(), 500);
  }
}
export function* resetUserSaga() {
  yield takeLatest(getType(resetUser), resetUserFn);
}
