import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { VerificationType } from "Pages/VerificationPage";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  BankIDVerificationStatus,
  setBankIDVerificationStatus,
} from "State/Verification/VerificationReducer";
import styled from "styled-components";
import { ObjectSchema, object, mixed } from "yup";

type FormModel = {
  verificationType: VerificationType;
};

type Props = {
  onSubmit: (verificationType: VerificationType) => void;
};

const StyledSubmitButton = styled(PrimaryButton)`
  margin: ${props => props.theme.spacing(1, 0)};
`;

const RadioButtonWrapper = styled.div``;

export const GuidepostVerificationStep: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const validationSchema: ObjectSchema<FormModel> = object({
    verificationType: mixed<VerificationType>()
      .oneOf(Object.values(VerificationType))
      .required(),
  }).defined();

  const { handleSubmit, control } = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      verificationType: VerificationType.Application,
    },
  });

  const dispatch = useDispatch();

  const onSubmit = ({ verificationType }: FormModel) => {
    if (verificationType === VerificationType.Application) {
      props.onSubmit(verificationType);
    } else {
      const bankIDUrl = import.meta.env.VITE_BANK_ID_URL;
      if (!!bankIDUrl) {
        dispatch(
          setBankIDVerificationStatus(BankIDVerificationStatus.Processing),
        );

        const scope =
          "&scope=profile.birthnumber%20profile.verification%20profile.legalstatus%20profile.paymentAccounts%20profile.email%20openid%20profile.name%20profile.birthplaceNationality%20profile.idcards";

        window.location.href = `${bankIDUrl}${scope}&state=verification`;
      }
    }
  };

  return (
    <>
      <br />
      <Typography>
        Nyní potřebujeme skeny Vašich dokladů a ověření bankovního účtu.
      </Typography>
      <br />
      {/* <Typography>
        Tyto kroky můžete udělat ručně nebo využít Bankovní identitu, která nám
        Vaši totožnost a vlastnictví bankovního účtu potvrdí.
      </Typography>
      <br /> */}

      <Typography sx={{ color: theme => theme.palette.text.secondary }}>
        Způsob ověření totožnosti
      </Typography>

      <form onSubmit={handleSubmit(data => onSubmit(data))}>
        <RadioButtonWrapper>
          <RadioButtons
            control={control}
            name="verificationType"
            codeList={[
              {
                code: VerificationType.Application,
                name: "Ručně",
                description: "Nahraju doklady a pošlu ověřovací platbu",
              },
              // {
              //   code: VerificationType.BankID,
              //   name: "Ověřím se Bankovní identitou",
              //   description:
              //     "BankID je aktuálně podporováno v AirBank, ČSAS, ČSOB, Komerční Banka, Moneta, Reiffeisen",
              // },
            ]}
          />
        </RadioButtonWrapper>
        <StyledSubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          {"Další krok"}
        </StyledSubmitButton>
      </form>
    </>
  );
};
