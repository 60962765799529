import { Typography } from "@mui/material";
import type { ApplicationLink } from "Components/Layout/Menu/Menu";
import {
  GmailLogoIcon,
  AskQuestionIcon,
  LogoutRoundedIcon,
  MoreIcon,
  MerchantAccountIcon,
  SelectUsersIcon,
  HomeIcon,
  ForwardArrowIcon,
  SortingArrowsIcon,
} from "Components/Shared/Icons";
import { AccessRightCodes } from "Models/AccessRightCodes";
import { useDispatch } from "react-redux";
import { resetUser } from "State/Auth/AuthReducer";
import { Resources, useResource } from "Translations/Resources";
import { getAppUrl } from "Utils/UrlUtils";

const MenuItemsResources = Resources.Menu.MainMenuItems;

export const useMenuLinks = () => {
  const { t } = useResource();
  const appUrl = getAppUrl();
  const dispatch = useDispatch();

  const topLinks = {
    overview: {
      text: <Typography>{t(MenuItemsResources.Overview)}</Typography>,
      to: appUrl("dashboard"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <HomeIcon />,
    },
    merchantPayment: {
      text: <Typography>{t(MenuItemsResources.InitiatePayment)}</Typography>,
      to: appUrl("merchant-payment"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <ForwardArrowIcon />,
    },
    transactions: {
      text: <Typography>{t(MenuItemsResources.History)}</Typography>,
      to: appUrl("transactions"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <SortingArrowsIcon />,
    },
    bankAccounts: {
      text: <Typography>{t(MenuItemsResources.Accounts)}</Typography>,
      to: appUrl("bank-address-list"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <MerchantAccountIcon />,
    },
    settings: {
      text: <Typography>{t(MenuItemsResources.Settings)}</Typography>,
      to: appUrl("settings"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <MoreIcon />,
    },
  };

  const bottomLinks = {
    users: {
      text: <Typography>{"Uživatelé"}</Typography>,
      to: appUrl("user-list"),
      accessRightCode: AccessRightCodes.Users,
      icon: <SelectUsersIcon />,
    },
    contact: {
      text: <Typography>{"Napište nám"}</Typography>,
      to: appUrl("contact"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <GmailLogoIcon />,
    },
    help: {
      text: <Typography>{"Nápověda"}</Typography>,
      to: "https://legi.one",
      accessRightCode: AccessRightCodes.Dashboard,
      isExternalLink: true,
      icon: <AskQuestionIcon />,
    },
    logout: {
      text: <Typography>Odhlásit se</Typography>,
      to: appUrl("sign-in"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <LogoutRoundedIcon />,
      onClick: () => {
        dispatch(resetUser({ isPageReloadDisabled: true }));
      },
    },
  } satisfies Record<string, ApplicationLink>;

  return { ...topLinks, ...bottomLinks };
};
