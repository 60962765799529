import { useQuery } from "@tanstack/react-query";
import { getLicensesTypes } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const LICENSE_QUERY_KEY = "LICENSE";

export const getLicenseQueryKey = (countryAlpha2Code?: string) => [
  LICENSE_QUERY_KEY,
  countryAlpha2Code,
];

export const useLicenseQuery = (countryAlpha2Code?: string) =>
  useQuery({
    queryKey: getLicenseQueryKey(countryAlpha2Code),
    queryFn: processResponse(() => getLicensesTypes(countryAlpha2Code)),
    enabled: !!countryAlpha2Code,
    refetchOnWindowFocus: false,
  });
